.venumonkApp {
  max-width: 564px;
  margin:0 auto;
}


.App-Background {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.align-center{
  align-items: center;
}

.FontsStyle{
  align-items:center;
  color: rgba(26, 26, 26, 0.87);
  letter-spacing: 0.25px;
  line-height: 1.5;
  font-size: 14px;
  text-align: center;

}

.FontsStyle1{
  align-items:center;
  color: #1a1a1a;
  letter-spacing: 0.25px;
  line-height: 1.5;
  font-size: min(4vw, 16px);
  text-align: center;
  padding-top: 150px;
  padding-bottom: 100px;
  border: 1px;
  border-radius: 4px;
  border-color: #1a1a1a;
}

.FontHeading{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1A1A1A;
}

.FontSubHeading{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 160%;
padding-top: 14px;
text-align: center;

color: rgba(26, 26, 26, 0.87)

}

.bg{
  width: 100%;
  height: 100%;
}

.VenuemonkLogo{
  width: 142.74px;
  height: 24px;
  position: relative;
  padding-top: 16px;
  z-index: 2;
}

.OverlayIMG{
  width: 288px;
  position: relative;
  height: 239px;
  z-index: 3;
}

.Banner-styles{
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  padding-top: -20px;
}


.footer{
  background-color: #F7F7F7;
}


.FontSmall{
  font-size: 10px;
}

.disclaimerFooter{
  margin-left: 16px;
  margin-right: 16px;
  left: calc(50% - 328px/2);
  top: 881px;
  padding-top: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 160%;
  text-align: center;
  color: rgba(26, 26, 26, 0.87);
  
}



.PartyButton{
  font-size: 23px;
  color: white;
  font: 'Poppins';
  font-style: normal;
  font-weight: 600;
  width: 94%;
  height: 42px;
  background-color: #592B7E;
  padding-bottom: 8px;
  padding-top: 7px;
  border-radius: 4px;
  border:0px;
}
